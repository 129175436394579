<template>
  <div>
    <Slider />
    <Description section="fintech" />
    <Strengths :strengths="strengths" />
    <Slogan />
    <TestimonialsPage />
  </div>
</template>

<script>
export default {
    name: "Fintech",
       components: {
       Slider: () => import('./sections/sliders/fintech'),
       Description: () => import('./sections/description'),
       Strengths: () => import('./sections/strengths'),
       Slogan: () => import('./sections/slogan.vue'),
       TestimonialsPage: () => import('../home/testimonials'),
    },
    computed: {
       strengths () {
         const data = [
             {
                    image: 'Pie_chart.png',
                    title: '24/7 Support',
                    text: 'We pride ourselves in the ability to provide round-the -clock support to all our customers with short SLAs of less than 5 minutes.',
                    color: '#d29f2a',
                },
                {
                    image: 'Note.png',
                    title: 'One-stop Shop Convenience',
                    text: 'Once registered as a customer you have access to all your payment needs on the go, using one unified platform.',
                    color: '#ffffff',
                },
             {
                    image: 'Pie_chart.png',
                    title: 'Security',
                    text: 'We prioritize security over everything. We opearate at the highest level of security standards as guided by the PCI/DSS Security Standards Council. ',
                    color: '#d29f2a',
                },
                  {
                    image: 'Note.png',
                    title: 'Dynamism ',
                    text: 'We are constantly exploring, testing and adding new features, so we can provide our customers with the best possible toolsets. By the time you see this message, all our systems will have morphed.',
                    color: '#ffffff',
                },
                
              
                
         ];
         return data;
       }

    },

}
</script>

<style>

</style>